<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-button style="float: right" type="primary" size="small" @click="$router.back()">返回</el-button>


        <el-descriptions title="用户信息" direction="vertical" :column="4" border>
            <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
            <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
            <el-descriptions-item label="会员等级" :span="2">苏州市</el-descriptions-item>
            <el-descriptions-item label="会员时长">
                2022-11-11 ~ 2023-11-11
            </el-descriptions-item>
        </el-descriptions>



        <div style="margin-top: 30px;">
            <h2 class="el-descriptions__title" style="margin-bottom: 20px;">购买记录</h2>
            <tp-table
                :isNeedSerialNumber="true"
                :tableData="list"
                :columns="columns"
                :totalNum="total"
                :current-page.sync="currentPage"
                :pageSize.sync="page_size"
            />
        </div>
    </div>
</template>

<script>
import {getList, getAllMember, detail, getDownloadOrderListAPI} from './api'

const columns = [
    {
        label: '订单编号',
        prop: 'order_sn',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['order_sn'] || '-'}</p>
        }
    },
    {
        label: '金额',
        prop: 'pay_amount',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['pay_amount'] || '-'}</p>
        }
    },
    {
        label: '支付时间',
        prop: 'created_at',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['created_at']}</p>
        }
    },
    {
        label: '支付方式',
        prop: 'pay_type_dsc',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['pay_type_dsc']}</p>
        }
    },
    {
        label: '支付流水',
        prop: 'transaction_id',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['transaction_id']}</p>
        }
    },
    // {
    //     label: '会员有效期',
    //     prop: 'nickname',
    //     minWidth: '120',
    //     customRender(h, row) {
    //         return <p>{row['nickname']}</p>
    //     }
    // },
]
export default {
    name: 'List',

    data() {
        return {
            columns,
            currentPage: 1,
            page_size: 10,
            total: 0,
            list: [],
            memberList: [
                {
                    id: 0,
                    title: '请选择'
                }
            ],
            searchData: {
                uid: 0,
                type: '',
            }
        }
    },
    provide() {
        return {
            context: this
        }
    },
    watch: {
        currentPage() {
            this.getList()
        },
        page_size() {
            this.getList()
        }
    },
    mounted() {
        this.searchData.uid = this.$route.params.id

        this.getList()
        this.getUserDetail(this.$route.params.id)
    },

    methods: {
        async getUserDetail(id) {
            this.user = await detail(id)
        },

        async getList() {
            const {uid} = this.searchData
            let params = {page: this.currentPage, page_size: this.page_size, uid}
            const res = await getDownloadOrderListAPI(params)
            this.list = res.data || []
            this.total = res.total || 0
        },
        goEdit(id) {
            this.$router.push({name: 'MemberEdit', params: {id: id || undefined}})
        },
        async getAllMember() {
            this.memberList = await getAllMember({})
        },
        // 加入黑/白名单
        // changeStatus({ id, status }) {
        //   console.log(id, status)
        //   let data = { id, riskLevel: status == 1 ? 2 : 1 }
        //   this.$confirm(`是否将该用户加入${status == 1 ? '黑' : '白'}名单, 是否继续?`, '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   })
        //     .then(() => {
        //       addRosterAPI(data).then(() => {
        //         this.getList()
        //         this.$message.success('操作成功')
        //       })
        //     })
        //     .catch(() => {
        //       this.$message({
        //         type: 'info',
        //         message: '已取消操作'
        //       })
        //     })
        // },
        reset() {
            Object.assign(this._data.searchData, this.$options.data().searchData)
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;

    .header-search {
        .el-form /deep/ {
            .el-form-item {
                margin-right: 35px;

                .el-input {
                    width: 250px;
                }

                .el-date-editor {
                    .el-range-separator {
                        padding: 0;
                    }

                    .el-range-input {
                        width: 35%;
                    }
                }
            }
        }
    }

    .el-divider--horizontal {
        margin: 0 0 20px;
    }
}
</style>
